const defaults = {
  loginPage: {
    asperaOnCloud: 'Aspera on Cloud',
    copyright: 'IBM Aspera on Cloud. 2016, 2023 © Copyright IBM Corporation. All rights reserved.',
    alreadyHaveAccount: 'Do you already have an account? $l/Log in/$l to continue.',
    myIssue: 'Please make sure the user exists in the organization and is allowed to log in via IBMid. You are logged in as $0'
  },
  errorPage: {
    unauthorizedTitle: 'Your registration link has expired.',
    unauthorizedSubtitle: 'Please contact your administrator for a new one.',
    refreshText: 'Refresh',
    invalidScopeTitle: 'Invalid Scope',
    invalidScopeSubtitle: "The 'scope' requested isn't allowed according to the rules in the documentation. It must be one or more of $0",
    missingParameterTitle: 'Missing Parameter',
    missingParameterSubtitle: 'The $l/OAuth RFC/$l requires $0 to be included.',
    unauthorizedClientTitle: 'Unauthorized Client',
    unauthorizedClientSubtitle: "Your OAuth client is not authorized. There are a few reasons this could happen according to the $l/OAuth RFC/$l. For example, if your client is requesting a 'response_type' with 'token' - chances are your client is not authorized to do that.",
    invalidScopeCurrentUserSubtitle: "The 'scope' requested isn't allowed according to the rules in the documentation. It must be one or more of $0. Not $1?",
    missingParameterCurrentUserSubtitle: 'The $l/OAuth RFC/$l requires $0 to be included. Not $1 ?',
    signInUnderDiffAccount: 'Sign in under a different account',
    unauthorizedClientCurrentUserSubtitle: "Your OAuth client is not authorized. There are a few reasons this could happen according to the $l/OAuth RFC/$l. For example, if your client is requesting a 'response_type' with 'token' - chances are your client is not authorized to do that. Not $0?",
    inviteOnlyTitle: 'Invite Only',
    inviteOnlySubtitle: 'Please contact your account manager to get access.',
    accountDeactivatedTitle: 'Account Deactivated',
    accountDeactivatedSubtitle: "Contact your organization's administrator.",
    invalidShortLinkTitle: 'Link Not Found',
    invalidShortLinkSubtitle: 'Link has been disabled or invalid link URL.',
    unverifiedEmailTitle: 'Unverified email',
    organizationNotFoundTitle: 'Organization not found',
    organizationNotFoundSubtitle: 'Please check your org name or ID.',
    lockedTitle: 'Too many failed logins',
    lockedSubtitle: 'Please check your email to reset your password.',
    invalidIbmUserErrorTitle: 'User is not valid',
    invalidIbmUserErrorSubtitle: "Please make sure the user exists in the organization and is allowed to log in via IBMid. You are logged in as $0.",
    invalidIbmUserErrorSubtitleDeactivated1Subtitle: 'You are not authorized to access your account. Your user is now deactivated due to inactivity.',
    invalidIbmUserErrorSubtitleDeactivated2Subtitle: 'You are not authorized to access your account.',
    invalidIcpUserErrorTitle: 'User cannot use ICP to authenticate',
    invalidIcpUserErrorSubtitle: 'Please use your own login provider to login.',
    loginScreenText: 'Click here to go back to the login screen.',
    invalidIcpUserOauthTitle: 'User is not valid',
    invalidIcpUserOauthSubtitle: 'Please make sure the user exists in the organization and is allowed to log in via ICP. You are logged in as $0.',
    logoutText: 'Click here to logout and change accounts.',
    invalidIbmIdResponseTitle: 'Response from IBMid is invalid',
    invalidIbmIdResponseSubTitle: 'Please contact your administrator for help.',
    invalidIcpIdResponseTitle: 'Response from ICP is invalid',
    invalidIcpIdResponseSubTitle: 'Please contact your administrator for help.',
    invalidIbmIdUserTitle: 'User cannot use IBMid to authenticate',
    invalidIbmIdUserSubtitle: 'Please use your own login provider to login.',
    insecureRedirectTitle: 'Security Warning',
    insecureRedirectSubtitle: "You're about to give an insecure site access to your files.",
    continue: 'Continue',
    exitText: 'Exit to IBM Aspera on Cloud home',
    unknownResponseTypeTitle: "Unknown Response Type",
    unknownResponseTypeSubtitle: "The 'response_type' specified is not one that our server supports. Only 'code' and 'token' are supported.",
    unregisteredClientTitle: "Unregistered Client",
    unregisteredClientSubtitle: "The client requested is not known.  Add a new API client or make sure the name exactly matches the 'client_id' query parameter.",
    unregisteredRedirectUriTitle: "Unregistered Redirect Error",
    unregisteredRedirectUriSubtitle: "Add the redirect URI for your API client in the admin application of IBM Aspera on Cloud and make sure it matches 'redirect_uri' query parameter exactly.",
    unknownResponseTypeCurrentUserSubtitle: "The 'response_type' specified is not one that our server supports. Only 'code' and 'token' are supported right now. Not $0?",
    unregisteredClientCurrentUserSubtitle: "The client requested is not known.  Add a new API client or make sure the name exactly matches the 'client_id' query parameter. Not $0?",
    unregisteredRedirectUriCurrentUserSubtitle: "Add the redirect URI for your API client in the admin application of IBM Aspera on Cloud and make sure it matches 'redirect_uri' query parameter exactly. Not $0?",
    invalidTokenTitle: 'Invalid Token',
    invalidTokenSubtitle: "The 'token' authentication for self-registration failed. Please use a valid token.",
    invalidTokenCurrentUserSubtitle: "The 'token' authentication for self-registration failed. Please use a valid token. Not $0?",
  }
};

export default defaults;
