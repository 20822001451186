/**
 * Map of languages that are supported in Aspera on Cloud.
 * Ordered by language code.
 * English languages are there for date format. But are not translated currently (not shown to user).
 *
 * Location assumes /assets/language and JSON files. Change below if not that.
 */
export const availableLanguages = new Map([
  ['ar-ar', {
    code: 'ar-ar',
    name: 'اَلْعَرَبِيَّةُ (اَلْعَرَبِيَّةُ)',
    location: '/assets/language/ar-ar.json',
    dateFormat: 'd/m/Y',
    rightToLeft: true,
    npsKey: 'ar-ar',
  }],
  ['de-de', {
    code: 'de-de',
    name: 'Deutsches (Deutschland)',
    location: '/assets/language/de-de.json',
    dateFormat: 'd.m.Y',
    timeFormat24: true,
    npsKey: 'de',
  }],
  ['en-us', {
    code: 'en-us',
    name: 'English (United States)',
    dateFormat: 'm/d/Y',
    npsKey: 'en',
  }],
  ['en-au', {
    code: 'en-au',
    name: 'English (Australia)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-bz', {
    code: 'en-bz',
    name: 'English (Belize)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-ca', {
    code: 'en-ca',
    name: 'English (Canada)',
    hidden: true,
    dateFormat: 'Y-m-D',
    npsKey: 'en',
  }],
  ['en-gb', {
    code: 'en-gb',
    name: 'English (United Kingdom)',
    hidden: true,
    dateFormat: 'd/m/Y',
    timeFormat24: true,
    npsKey: 'en',
  }],
  ['en-ie', {
    code: 'en-ie',
    name: 'English (Ireland)',
    hidden: true,
    dateFormat: 'd/m/Y',
    timeFormat24: true,
    npsKey: 'en',
  }],
  ['en-jm', {
    code: 'en-jm',
    name: 'English (Jamaica)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-nz', {
    code: 'en-nz',
    name: 'English (New Zealand)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-ph', {
    code: 'en-ph',
    name: 'English (Republic of the Philippines)',
    hidden: true,
    dateFormat: 'm/d/Y',
    npsKey: 'en',
  }],
  ['en-tt', {
    code: 'en-tt',
    name: 'English (Trinidad and Tobago)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-za', {
    code: 'en-za',
    name: 'English (South Africa)',
    hidden: true,
    dateFormat: 'Y-m-d',
    npsKey: 'en',
  }],
  ['en-zw', {
    code: 'en-zw',
    name: 'English (Zimbabwe)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['es-es', {
    code: 'es-es',
    name: 'Español (España)',
    location: '/assets/language/es-es.json',
    dateFormat: 'd/m/Y',
    timeFormat24: true,
    npsKey: 'es',
  }],
  ['fr-fr', {
    code: 'fr-fr',
    name: 'Français (France)',
    location: '/assets/language/fr-fr.json',
    dateFormat: 'd/m/Y',
    timeFormat24: true,
    npsKey: 'fr',
  }],
  ['ja-jp', {
    code: 'ja-jp',
    name: '日本語 (日本)',
    location: '/assets/language/ja-jp.json',
    dateFormat: 'Y年m月d日',
    timeFormat24: true,
    npsKey: 'ja',
  }],
  ['ko-kr', {
    code: 'ko-kr',
    name: '한국어 (대한민국)',
    location: '/assets/language/ko-kr.json',
    dateFormat: 'Y-m-d',
    npsKey: 'ko',
  }],
  ['ru-ru', {
    code: 'ru-ru',
    name: 'Русский (Россия)',
    location: '/assets/language/ru-ru.json',
    dateFormat: 'd.m.Y',
    timeFormat24: true,
    npsKey: 'ru',
  }],
  ['zh-cn', {
    code: 'zh-cn',
    name: '简体中文',
    location: '/assets/language/zh-cn.json',
    dateFormat: 'Y-m-d',
    npsKey: 'zh',
  }],
  ['zh-tw', {
    code: 'zh-tw',
    name: '繁體中文',
    location: '/assets/language/zh-tw.json',
    dateFormat: 'Y/m/d',
    npsKey: 'zh-tw',
  }],
]);
